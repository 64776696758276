@use "../../config" as *;
@use "../../mixins" as *;

#survey-start, #intro{

    .description{
        &.simparica-answers{
            p{
                @include gotham-medium();
                color: map_get($colors-form, text);
                font-size: 34px;
            }

        }
    }

    .answers{
        margin:50px auto;
        display:flex;
        justify-content: center;
        align-items: stretch;
        flex-direction: row;
        gap: 25px;

        .dose{
            width: 220px;
            display: flex;
            flex-direction: column;
            border-radius: 20px;

            &:last-child{
                label{
                    //font-size:20px;

                }
            }

            //&:not(:last-child) {
            //    label {
            //        border-right: none;
            //    }
            //}
            //
            //&:first-child {
            //    .dose-value {
            //        border-top-left-radius: 20px;
            //    }
            //
            //    label {
            //        border-bottom-left-radius: 20px;
            //    }
            //}
            //
            //&:last-child {
            //    .dose-value {
            //        border-top-right-radius: 20px;
            //    }
            //
            //    label {
            //        border-bottom-right-radius: 20px;
            //    }
            //}


            .dose-value {
                flex: 1 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
                @include gotham-bold();
                font-size: 14px;
                min-height: 60px;
                border-radius: 20px 20px 0 0;
                margin: 0;
                padding: 5px;
                color: map_get($colors-dose-select, text-hover);
                background-color: map_get($colors-dose-select, bg-hover);
            }

            label{
                flex: 0 0 110px;
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 0 0 20px 20px;
                border: 3px solid map_get($colors-dose-select, border);
                border-top: none;

                padding: 0 7px;
                margin: 0;
                color: map_get($colors-dose-select, dose-reward);
                background: map_get($colors-dose-select, bg-color);

                @include gotham-bold();
                font-size:28px;
                line-height:1;
                text-align: center;

                transition: background-color 0.4s ease, color 0.4s ease;
                cursor: pointer;

                span {
                    font-size:45px;
                }

                sup {
                    top: -0.24em;
                    margin-right: 2px;
                }

                &::before{
                    display: none;
                }

                &:hover, &.active{
                    background-color: map_get($colors-dose-select, bg-hover-main-dark);
                    color: map_get($colors-dose-select, text-hover);
                    svg{
                        path{
                            fill: map_get($colors-dose-select, bg-color);
                        }
                    }
                }

                svg{
                    margin-top: 13px;
                }
            }
        }
    }

    .btn-start{
        margin-bottom: 40px;
    }

    .nav{
        display: flex;
        justify-content: center !important;

        .download-pdf{
            &:hover{
                p{
                    color: map_get($colors-general, hover);
                }
            }
            p{
                text-decoration: underline;
                font-size: 18px;
                @include gotham-book();
                margin: 0;
            }

        }
    }
    .pdf-download{
        position: absolute;
        bottom:0;
        left:10px;

        &:hover{
            p{
                text-decoration: underline;
            }
        }

        svg{
            width: 60px;
        }

        p{
            margin-top: 5px;
            @include gotham-book();
            font-size: 11px;
        }
    }



    @media screen and (max-width : $xl-min) {
        .answers{
            label{

                //padding:7px;
                //font-size:26px;
            }
        }
    }

    @media screen and (max-width : $lg-min) {
        .answers{
            label{
                //padding:5px;
                //font-size:18px;
            }
        }
    }

    @media screen and (max-width : $md-min) {
        p.text {
            margin-bottom: 60px;
        }

        .pdf-download{
            p{
                br{
                    display:block;
                }
            }
        }
    }

    @media screen and (max-width : $sm-min) {
        p.text {
            margin-bottom: 18px;
        }

        .arrow.next:not(.btn-start){
            display: none;

            //span{
            //    top: 14px;
            //    right: 65px;
            //    font-size: 15px;
            //}
        }
        .description{
            &.simparica-answers{
                p{
                    font-size: 22px;
                }

            }
        }

        .answers{
            margin: 25px auto;
            padding: 0;
            max-width: 95%;
            gap:10px;

            .dose{
                max-width: 24%;

                .dose-value {
                    min-height: 40px;
                    font-size: 11px;

                    br {
                        display: none;
                    }
                }

                label{
                    font-size:16px;
                    flex: 0 0 100px;

                    span {
                        font-size: 28px;
                    }
                    svg{
                        width: 24px;
                        margin-top: 4px;
                    }
                }
            }
        }

        div.nav{
            justify-content: center;
            .download-pdf{
                margin-bottom: 15px;
                p{
                    font-size: 16px;
                }
            }
        }


        .pdf-download{
            //float:left;
            position: relative;
            bottom:auto;
            left:auto;
            margin-top: 10px;

            svg{
                width: 45px;
            }


            p{
                font-size:10px;
            }
        }

    }

    @media only screen and (max-width: 450px){
        .answers.simparica-trio-answers{
            max-width: 100%;
            flex-wrap: wrap;
            .dose{
                max-width: 31% !important;
            }
        }
    }
}
