@mixin gotham-light(){
    font-family: 'Gotham Narrow A', 'Gotham Narrow B', "Times New Roman", helvetica, sans-serif;
    font-weight: 300;
}

@mixin gotham-book(){
    font-family: 'Gotham Narrow A', 'Gotham Narrow B', "Times New Roman", helvetica, sans-serif;
    font-weight: 400;
}

@mixin gotham-medium(){
    font-family: 'Gotham Narrow A', 'Gotham Narrow B', "Times New Roman", helvetica, sans-serif;
    font-weight: 500;
}

@mixin gotham-bold(){
    font-family: 'Gotham Narrow A', 'Gotham Narrow B', "Times New Roman", helvetica, sans-serif;
    font-weight: 700;
}

@mixin helvetica(){
    font-family: helvetica, Arial, sans-serif;
    font-weight: normal;
}
