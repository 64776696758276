@use "../../config" as *;
@use "../../mixins" as *;

#din {
    p, label {
        @include gotham-book();
    }

    label, p.notice {
        font-size: 21px;
    }

    .title {
        @include gotham-bold();
        font-weight: 700;
        padding-inline: 7rem;
        margin: 0 auto;
    }

    .products-dosage-img {
        margin: 0 auto;
        max-width: 940px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        img {
            margin-bottom: 18px;
        }
    }

    .d-flex {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;

        > div {
            display: flex;
            align-items: center;

        }

        input {
            padding-block: 9px;
        }

        button {
            &.btn {
                margin-left: 1rem;
                margin-top: 0;
            }
        }
    }

    @media screen and (max-width: $xl-min) {

    }

    @media screen and (max-width: $lg-min) {

        .products-dosage-img {
            max-width: 800px;

            img {
                width: 240px;
            }
        }

        label, p.notice {
            font-size: 18px;
        }
    }

    @media screen and (max-width: $md-min) {
        .products-dosage-img {
            max-width: 600px;

            img {
                width: auto;
            }
        }

        label, p.notice {
            font-size: 15px;
        }

    }

    @media screen and (max-width: $sm-min) {
        label, p.notice {
            font-size: 12px;
        }

        .error-msg {
            margin-bottom: 0;
        }

        .products-dosage-img {
            max-width: 300px;

            img {
                width: 100%;
                max-width: 240px;
                margin: 0 auto 15px;

                &:nth-child(odd) {
                    display: none;
                }
            }
        }

        button {
            font-size: 14px;
            padding: 8px 23px;
        }
    }

    @media screen and (max-width: $xs-max) {
        .title {
            padding-inline: 1rem;
        }
        .d-flex {
            > div {
                flex-direction: column;

                button {
                    &.btn {
                        width: 100%;
                        margin-top: 1rem;
                        margin-left: 0;
                    }
                }
            }
        }

    }
}
