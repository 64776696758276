@use "../../config" as *;
@use "../../mixins" as *;

form.has-server-error, form.has-error {
    .error-message {
        display: block !important;
    }
}

#user-form{
    text-align: left;

    &.form-error {
        .error-message{
            display: block;
        }
    }

    .error-message{
        margin-top: 30px;
        display:none;
        text-align: center;
        @include gotham-medium();
        color: map_get($colors-user-form, error);
    }

    p{
        padding:0;
    }

    label{
        font-size:16px;
        line-height:22px;
        margin-right: 8px;

        &.left{
            float:left;
        }

        &.error{
            color: map_get($colors-user-form, error);
        }
    }

    .row{
        margin: 16px 0;

        &.has-radio-btns {
            margin: 0;

            .answers {
                .text-field {
                    margin-top: 2px;
                }
            }
        }

        .half{
            width: calc(50% - 10px);
            float:left;

            &:first-child{
                margin-right: 10px;
            }
            &:last-child{
                margin-left: 10px;
            }
        }

        p{
            font-size:16px;
            display: inline-block;
            margin:0 10px 0 0;
        }

        .answers {
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;

            .mdc-form-field {
                margin: 0;
            }

            .mdc-radio {
                & ~ label {
                    padding: 0;
                }
            }
        }

        label.answer{
            padding-top: 7px;
            display: inline;
            margin-right: 25px;
        }

        span.input-wrapper{
            display: block;
            overflow: hidden;

            input{
                width: 100%;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
                box-sizing: border-box;
            }
        }

        &.opt-in-wrapper {
            position: relative;

            &.active{
                label{
                    &:before{
                        background-position: bottom;
                    }
                }

            }

            input[type=checkbox]{
                display:none;
            }

            label{
                padding-left: 33px;
                display:block;

                &:before{
                    position: absolute;
                    top: -7px;
                    left:0;
                    content: '';
                    display:inline-block;
                    width: 27px;
                    height:29px;
                    background-size: 100% auto;
                    background-position: top;
                    background-image:url('../images/form/checkbox.png');
                    line-height:1;
                }
            }
        }

        &.city-wrapper{
            >div{
                float:left;

                &:nth-child(1){
                    //city
                    width: calc(55% - 75px);
                }
                &:nth-child(2){
                    //province
                    width: 110px;
                    margin: 0 20px;

                    input{
                        width: 40px;
                    }

                }
                &:nth-child(3){
                    //postal code
                    width: calc(45% - 75px);
                }
            }
        }
    }

    input{
        padding: 0px 6px;
        font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
        font-size: 12px;

        &:not([type='radio']) {
            height: 22px;
        }

        &[name=age]{
            width: 40px;
        }

        &.has-error{
            border-color: map_get($colors-user-form, error);
        }
    }

    .inline{
        display:inline;
    }

    .file-upload{
        position: relative;
        text-align: left;

        label{
            font-size: 12px;
        }

        .file-upload-button-wrapper{
            position: relative;
            display: inline-block;
            width: 88px;

            .file-upload-button{
                position: absolute;
                top: 0px;
                margin:0 7px 0 0;
                width: 78px;
                height: 23px;
                background-size: 100% auto;
                display:inline-block;
            }

            input[type=file]{
                position: relative;
                z-index: 200;
                opacity: 0;
                text-indent: -91px;
                margin: 0 auto;
                padding:0;
                height: 23px;
                background-size: 100% auto;
                cursor: pointer;
                opacity:0;
            }
        }

        .file-name{
            color: map_get($colors-general, text);
            font-size: 11px;
            @include gotham-book();
        }
    }

    .required-info{
        font-size:9px;
        margin: 3px 0;
    }

    input[type=submit]{
        margin: 0 auto;
        display:block;
        color: map_get($colors-user-form, button-text);
        background-color: map_get($colors-user-form, button);
        border: none;
        text-transform: uppercase;
        padding: 2px 15px;
        line-height: 16px;
        border-radius: 0;
        @include gotham-book();
        cursor:pointer;

        &:hover{
            background-color: map_get($colors-user-form, button-hover);
        }
    }

    @media screen and (max-width : $xl-min) {
        .row{

            label.answer{
                padding-top: 10px;
            }
        }
    }

    @media screen and (max-width : $lg-min) {
        .row{

            label.answer{
                padding-top: 7px;
            }
        }
    }

    @media screen and (max-width : $md-min) {
        .row {


            label.answer {
                margin-right: 15px;
            }
        }
    }

    @media screen and (max-width : $sm-min) {
        .inline{
            display: block;

            &:first-child{
                margin-bottom: 15px;
            }
        }

        .row{
            &.has-radio-btns {
                margin: 16px 0 0;

                .answers {
                    .text-field {
                        margin-top: 6px;
                        width: 100%;

                        label {
                            margin: 0;
                        }
                    }
                }
            }

            .answers{
                padding:0;

                p {
                    width: 100%;
                    margin-bottom: 5px;
                }

                .answer{
                    display:inline-block;
                    width: auto;
                    white-space: nowrap;
                }
            }

            label, input{
                width: 100%;
                box-sizing: border-box;
            }

            span.input-wrapper{
                overflow: auto;
                display: initial;
            }

            .half{
                width: 100%;

                &:first-child{
                    margin: 0 0 15px;
                }

                &:last-child{
                    margin:0;
                }
            }

            &.city-wrapper{
                >div{

                    &:nth-child(1){
                        //city
                        width: 100%;
                    }
                    &:nth-child(2){
                        //province
                        width: 100%;
                        margin: 15px 0;

                        input{
                            width: 100%;
                        }

                    }
                    &:nth-child(3){
                        //postal code
                        width: 100%;
                    }
                }
            }
        }
    }
}
