@use "../../config" as *;
@use "../../mixins" as *;

#ended-content{
    margin: 30px auto;

    h1{
        margin: 0 50px;
        @include gotham-medium();
        color: map_get($colors-general, text);
    }

    @media screen and (max-width : $xl-min) {

    }

    @media screen and (max-width : $lg-min) {

    }

    @media screen and (max-width : $md-min) {

    }

    @media screen and (max-width : $sm-min) {
        margin: 10px auto;

        h1{
            font-size: 18px;
        }
    }
}
