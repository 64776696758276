@use "../../config" as *;
@use "../../mixins" as *;

#form-success{
    padding-top: 70px;
    padding-bottom: 70px;

    @media screen and (max-width : $xl-min) {

    }

    @media screen and (max-width : $lg-min) {

    }

    @media screen and (max-width : $md-min) {

    }

    @media screen and (max-width : $sm-min) {

    }
}
