@use "../../config" as *;
@use "../../mixins" as *;

#intro {
    .description {
        p {
            font-size: 21px;
        }
    }


    .bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 0;

        img {
            width: 62%;
            height: auto;
            margin-bottom: 20px;
        }

        p {
            margin: 0;
            @include gotham-medium();
            font-size: 19px;
            width: 70%;
            color: map_get($colors-intro, notice);
        }
    }

    .nav {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pdf-download {
        max-width: 110px;
        cursor: pointer;

        &:hover {
            p {
                text-decoration: underline;
            }
        }

        svg {
            width: 70px;
        }

        p {
            @include gotham-book();
            font-size: 0.85rem;
            margin: 5px 0 0;
        }
    }

    button.btn {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
    }

    @media screen and (max-width : $lg-min) {
        .description {
            p {
                font-size: 18px;
            }
        }

        .bottom {
            p {
                font-size: 16px;
            }
        }
    }

    @media screen and (max-width : $md-min) {
        .description {
            p {
                font-size: 15px;
            }
        }

        .bottom {
            p {
                font-size: 13px;
            }
        }

        .pdf-download {
            position: static;
            margin: 0 auto;
            max-width: 200px;
        }
    }

    @media screen and (max-width: $sm-min) {
        .description {
            p {
                font-size: 12px;
            }
        }

        .bottom{
            margin: 18px 0;

            img {
                width: 95%;
                margin: 0 auto 15px;
            }

            p {
                font-size: 10px;
                width: 85%;
            }
        }

        .pdf-download {
            svg {
                width: 45px;
            }
        }

        button.btn {
            position: static;
            transform: none;
            font-size: 12px;
            padding: 13px;

            &.arrow {
                margin: 0 !important;
            }
        }
    }
}
