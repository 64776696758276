@use "../../config" as *;
@use "../../mixins" as *;

#form{
    margin: 0px auto 60px;
    max-width: 1300px;

    .contact-us{
        display: flex;
        justify-content: center;
        p{
            font-size: 1.325rem;
            @include gotham-bold();
            color: map_get($colors-general, text);
            width:50%;
            font-weight: 700;
        }
    }

    .form-container{
        position: relative;
        margin:0 35px;
        border-radius: 15px;
        -webkit-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.28);
        -moz-box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.28);
        box-shadow: 0px 0px 12px 0px rgba(0,0,0,0.28);
        display:flex;

        form{
            width: 100%;

            input[type=button], button {
                -webkit-appearance: none;
                -webkit-border-radius: 0;
                border:none;
                -webkit-tap-highlight-color: transparent;

            }
        }

        p, label{

            &.answer-information{

                strong{
                    @include gotham-bold();
                }
            }
        }

        .error {
            font-size: 14px;
            color: map_get($colors-form, error);
        }

        .error-msg{
            display: none;
            font-size: 14px;
            color: map_get($colors-form, error);

            &.display {
                display: block;
            }
        }

        .centered-text{
            margin-left:auto;
            margin-right:auto;
            max-width: 500px;

            &.answers{
                max-width: 900px;
            }
        }

        .arrow:not(.btn-start) {
            border:0;
            background-color: transparent;
            background-image: url('../images/form/arrow.png');
            background-size: 100% auto;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            width: 55px;
            height: 55px;
            transition: all 0.3s ease;
            cursor: pointer;

            &:disabled{
                opacity:0;
                cursor: default;
            }

            &.next{
                right:20px;
            }

            &.prev{
                left:20px;
                transform: translateY(-50%) rotate(180deg);
            }

            &:hover{
                transition: all 0.3s ease;
                &:after{
                    content: '';
                    background-color: transparent;
                    background-image: url('../images/form/arrow-hover.png');
                    background-size: 100% auto;
                    position: absolute;
                    top: 50%;
                    left:0;
                    transform: translateY(-50%);
                    width: 55px;
                    height: 55px;
                    transition: all 0.3s ease;
                }
            }

            br{
                display:block;
            }
        }
    }

    @media screen and (max-width : $xl-min) {

    }

    @media screen and (max-width : $lg-min) {
        .form-container{

            .centered-text{

                &.answers{
                    max-width: 700px;
                }
            }
        }
    }

    @media screen and (max-width : $md-min) {
        margin: 0px auto 25px;

        .form-container{
            margin:0 20px;

            .centered-text{

                &.answers{
                    max-width: 500px;
                }
            }
        }
    }

    @media screen and (max-width : $sm-min) {
        margin: 0px auto 15px;

        .form-container{
            margin:0 10px;

            .centered-text{

                &.answers{
                    max-width: 100%;
                }
            }

            .arrow:not(.btn-start){
                position: relative;
                top:50%;
                margin: 20px 0;

                &.next{
                    float:right;
                    transform: none;
                }

                &.prev{
                    float:left;
                    transform: rotate(180deg);
                }
            }
        }
    }


    @media screen and (max-width: $xs-max){
        .contact-us{
            p{
                width: unset;
                padding-inline: 1rem;
            }

        }
    }
}
