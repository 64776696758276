@use "../../config" as *;
@use "../../mixins" as *;

.form-slide {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;
    padding: 40px;
    width: 100%;
    box-sizing: border-box;

    &.active {
        position: relative;
        opacity: 1;
        pointer-events: auto;
    }

    textarea {
        max-width: 700px;
        width: 80%;
        margin: 0 auto;
        padding: 10px;
        box-sizing: border-box;
        @include gotham-book();
    }

    sup {
        &.md {
            font-size: 50%;
            top: -11px;
        }

        &.new-md {
            font-size: 43%;
            top: -20px;
        }

        &.md-legal {
            font-size: 65%;
            top: -5px;
        }
    }

    .answers {
        display: flex;
        flex-direction: column;

        .answer.small {
            margin: 0 100px;
        }

        label {
            cursor: pointer;
            text-align: left;
            line-height: 1;
            margin-left: 5px;
        }

        .mdc-form-field {
            margin: 0 0 5px;
        }

        .mdc-radio {
            &.hovered {
                .mdc-radio__ripple {
                    &:before {
                        opacity: 0.04;
                    }
                }
            }

            .mdc-radio__native-control {
                &:enabled {
                    & + .mdc-radio__background {
                        .mdc-radio__inner-circle {
                            border-color: map_get($colors-form, radio-bg);
                        }
                    }

                    &:not(:checked) + .mdc-radio__background {
                        .mdc-radio__outer-circle {
                            border-color: map_get($colors-form, input-border);
                        }
                    }

                    &:checked + .mdc-radio__background {
                        .mdc-radio__outer-circle  {
                            border-color: map_get($colors-form, radio-bg);
                        }
                    }
                }
            }

            .mdc-radio__ripple {
                &:before, &:after {
                    background-color: map_get($colors-form, radio-bg);
                }
            }
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    p, label {
        @include gotham-medium();
        color: map_get($colors-form, text);
        font-size: 34px;

        &.answer-information {
            color: map_get($colors-form, answer);
            opacity: 0;
            transition: opacity 0.3s ease;

            &.visible {
                opacity: 1;
            }
        }

        strong {
            @include gotham-bold();
        }
    }

    strong {
        @include gotham-medium();
    }

    input {
        border-radius: 2px;
        padding: 12px 18px;
        border: 1px solid map_get($colors-form, input-border);
    }

    @media screen and (min-width: $lg-min) {
        .answers {
            .mdc-radio {
                width: 30px;
                height: 30px;
                padding: calc((60px - 30px) / 2);

                .mdc-radio__native-control {
                    width: 60px;
                    height: 60px;
                    top: calc((60px - 60px) / 2);
                    right: calc((60px - 60px) / 2);
                    left: calc((60px - 60px) / 2);
                }

                .mdc-radio__background {
                    width: 30px;
                    height: 30px;

                    &:before {
                        top: calc(-1 * (60px - 30px) / 2);
                        left: calc(-1 * (60px - 30px) / 2);
                        width: 60px;
                        height: 60px;
                    }

                    .mdc-radio__inner-circle {
                        border-width: 15px;
                    }
                }
            }
        }
    }

    @media screen and (min-width: $md-min)  and (max-width: $md-max) {
        .answers {
            .mdc-radio {
                width: 24px;
                height: 24px;
                padding: calc((48px - 24px) / 2);

                .mdc-radio__native-control {
                    width: 48px;
                    height: 48px;
                    top: calc((48px - 48px) / 2);
                    right: calc((48px - 48px) / 2);
                    left: calc((48px - 48px) / 2);
                }

                .mdc-radio__background {
                    width: 24px;
                    height: 24px;

                    &:before {
                        top: calc(-1 * (48px - 24px) / 2);
                        left: calc(-1 * (48px - 24px) / 2);
                        width: 48px;
                        height: 48px;
                    }

                    .mdc-radio__inner-circle {
                        border-width: 12px;
                    }
                }
            }
        }
    }

    @media screen and (max-width: $md-max) {
        p {
            font-size: 26px;
            padding: 0 15px;
        }

        sup.md {
            font-size: 50%;
            top: -9px;
        }

        .answers {
            .answer.small {
                margin: 0 70px;
            }

            label {
                font-size: 26px;
            }
        }
    }

    @media screen and (max-width: $sm-max) {
        padding: 20px;

        p {
            font-size: 26px;
        }

        input {
            padding: 8px 12px;
        }

        br {
            display: none;
        }

        .answers {

            .answer.small {
                margin: 0 50px;
            }

            label {
                font-size: 26px;
            }
        }
    }

    @media screen and (max-width: $xs-max) {
        padding: 15px 10px;
        p {
            font-size: 18px;
        }

        sup.md {
            font-size: 50%;
            top: -7px;
        }

        .answers {
            padding: 0 20px;

            label {
                font-size: 18px;
            }
        }


        input {
            padding: 5px 10px;
        }

        br {
            display: none;
        }
    }
}
