@use "../../config" as *;
@use "../../mixins" as *;

#header{

    .slide-center-wrapper {
        position: absolute;
        z-index:3;
        top:0;
        right:50%;
        transform: translateX(50%);
        overflow: hidden;
        height: 363px;
        max-width: 51%;

        .slide-center {
            height: 393px;
            max-width: none;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .slides-ctn{
        position: relative;
        overflow:hidden;
        height: 363px;
        background-color: map_get($colors-header, bg);

        img{
            height: 100%;
            width: auto;
            max-width: none;
        }

        .slide{
            z-index:2;
            position: absolute;
            top:0;
            width: 100%;
            height: 100%;
            opacity:0;
            transition: opacity 0.8s ease;

            &.active{
                opacity:1;
            }
        }
    }

    @media screen and (max-width : $xl-min) {
        .slides-ctn, .slide-center-wrapper {
            height: 265px;
        }

        .slide-center-wrapper {
            .slide-center {
                height: 295px;
            }
        }
    }

    @media screen and (max-width : $lg-min) {
        .slides-ctn, .slide-center-wrapper{
            height: 190px;
        }

        .slide-center-wrapper {
            .slide-center {
                height: 219px;
            }
        }
    }

    @media screen and (max-width : $md-min) {
        .slides-ctn{

            .slide{
                display:none;
            }
        }

        .slide-center-wrapper {
            max-width: 100%;
        }
    }

    @media screen and (max-width : $sm-min) {
        .slides-ctn, .slide-center-wrapper{
            height: 120px;
        }

        .slide-center-wrapper {
            .slide-center {
                height: 149px;
            }
        }
    }
}
