@use "config" as *;
@use "mixins" as *;

//
// Root styles
// -----------------------------------------------------------------------------

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
}

#site-ctn {
    height: 100%;
    display: flex;
    flex-direction: column;

    .language-switcher-wrapper {
        max-width: 1300px;
        margin: 0 auto;
        position: relative;
        height: 1px;

        .language-switcher {
            position: absolute;
            right: 50px;
            top: 10px;
            @include gotham-medium();

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .content-wrapper {
        flex: 1 0 auto;
        text-align: center;
        overflow: hidden;
        .simparica-logos{
            display:flex;
            justify-content: space-between;

            .simparica-trio-logo {
                height: auto;
                margin: 45px auto;
            }
        }
    }

    #footer {
        flex-shrink: 0;
    }

    button.btn {
        padding: 13px 43px;
        color: map_get($colors-din, text);
        background-color: map_get($colors-din, bg);
        @include gotham-book();
        margin-top: 15px;
        text-transform: uppercase;
        transition: all 0.4s ease;

        &:hover {
            -webkit-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.28);
            -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.28);
            box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.28);
            background-color: map_get($colors-din, bg-hover);
        }
    }

    @media screen and (max-width: $xl-min) {
        .content-wrapper {

            .simparica-trio-logo {
                width: 420px;
            }
        }
    }

    @media screen and (max-width: $lg-min) {
        .content-wrapper {

            .simparica-trio-logo {
                margin: 25px auto;
                width: 380px;
            }
        }
    }

    @media screen and (max-width: $md-min) {
        .content-wrapper {

            .simparica-trio-logo {
                margin: 25px auto;
                width: 300px;
            }
        }
    }

    @media screen and (max-width: $sm-min) {
        .content-wrapper {

            .simparica-trio-logo {
                margin: 15px auto;
                width: 150px;
            }
        }

        .language-switcher-wrapper {


            .language-switcher {
                right: 25px;
                top: 18px;
            }
        }
    }
}

//
// Heading styles
// -----------------------------------------------------------------------------

h1 {
    margin: 0 50px;
    @include gotham-medium();
    color: map_get($colors-general, text);

    &.title {
        @include gotham-bold();
        font-size: 40px;
        color: map_get($colors-intro, title);
        text-transform: uppercase;
        margin: 0;

        span {
            color: map_get($colors-intro, title-contrast);
        }

        @media screen and (max-width: $md-min) {
            font-size: 40px;
        }

        @media screen and (max-width: $sm-min) {
            font-size: 24px;
        }
    }
}

h2 {
}

h3 {
}

h4 {
}

h5 {
}

h6 {
}

//
// Text styles
// -----------------------------------------------------------------------------

p {
    @include gotham-book();
}

//
// Link styles
// -----------------------------------------------------------------------------

a {
    &:hover, &:focus, &:active, &:visited {
    }

    color: map_get($colors-general, links);
}

//
// List styles
// -----------------------------------------------------------------------------

ul {
}

ol {
}

li {
}

//
// Form styles
// -----------------------------------------------------------------------------

input {
}
