@use "config" as *;
@use "mixins" as *;

ul.ui-autocomplete{
    z-index: 50;
    list-style: none inside none;
    padding: 5px 0;
    width: 234px !important;
    background-color: white;
    max-height: 200px;
    overflow: auto;
    border: 1px solid map_get($colors-form, input-border);

    li{
        padding: 5px 10px;
        font-size: 14px;
        @include gotham-book();

        &:hover{
            background-color: map_get($colors-form, input-hover);
        }

        a{
            color: map_get($colors-general, text);
        }
    }
}

.ui-helper-hidden-accessible{
    display:none;
}
