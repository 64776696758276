@use "../../config" as *;
@use "../../mixins" as *;

#footer{
    position: relative;
    padding: 20px 50px 10px;

    background-color: map_get($colors-footer, bg);

    p{
        color: map_get($colors-footer, text);
        margin-right: 430px;
        font-size:15px;
        @include helvetica();
    }

    .logo{
        position: absolute;
        right:50px;
        top:50%;
        transform: translateY(-50%);
    }

    @media screen and (max-width : $xl-min) {
        p{
            margin-right: 300px;
        }

        .logo{
            max-width: 150px;
            height:auto;
        }
    }

    @media screen and (max-width : $lg-min) {
        padding-left: 35px;
        padding-right: 35px;

        p{
            margin-right: 180px;
            font-size:12px;
        }

        .logo{
            max-width: 120px;
            right: 35px;
            height:auto;
        }
    }

    @media screen and (max-width : $md-min) {

    }

    @media screen and (max-width : $sm-min) {
        padding: 20px 20px 65px;

        p{
            margin:0;
            font-size:9px;
        }

        .logo{
            transform: none;
            top:auto;
            right: 20px;
            bottom: 10px;
            max-width: 100px;
        }
    }
}
